import React from 'react';
import { useInlineEditing, UseInlineEditingProps } from './useInlineEditing';
import "./InlineEditableSelect.scss";

interface SelectWithJAAttributeProps extends UseInlineEditingProps {
    label: string;
    value: string;
    options: { value: string; label: string }[];
    displayModeElement?: React.ReactNode;
    onChange: (value: string) => void;
}

const InlineEditableSelect: React.FC<SelectWithJAAttributeProps> = ({
    label,
    value,
    options,
    displayModeElement = null,
    onChange,
    ...hookProps
}) => {
    const {
        inEditMode,
        editableRef,
        handleEnterEditMode,
        handleAccept,
        handleReset,
    } = useInlineEditing(hookProps);

    return (
        <div className="columns is-2 is-vcentered mb-0">
            <div className="column is-4">
                <p className="has-text-weight-bold">{label}</p>
            </div>
            <div className="column is-8">
                {!inEditMode && (
                    <div className="select-display-mode-container" onClick={handleEnterEditMode}>
                        {!displayModeElement && value}
                        {displayModeElement}
                    </div>
                )}
                {inEditMode && (
                    <div
                        className="select-edit-mode-container"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleAccept();
                            if (e.key === 'Escape') handleReset();
                        }}
                    >
                        <select
                            ref={editableRef as React.RefObject<HTMLSelectElement>}
                            className="select is-small is-fullwidth"
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                        >
                            {options.map((opt) => (
                                <option key={opt.value} value={opt.value}>
                                    {opt.label}
                                </option>
                            ))}
                        </select>
                        <div className="edit-mode-buttons-container buttons has-addons">
                            <button className="button is-small" onClick={handleAccept}>
                                <span className="icon">
                                    <i className="fa-solid fa-check"></i>
                                </span>
                            </button>
                            <button className="button is-small" onClick={handleReset}>
                                <span className="icon">
                                    <i className="fa-solid fa-xmark"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InlineEditableSelect;
