import React from 'react';
import { useInlineEditing, UseInlineEditingProps } from './useInlineEditing';
import './InlineEditableInput.scss';

interface InlineEditableInputProps extends UseInlineEditingProps {
  label: string;
  value: string;
  isLink?: boolean;
  onChange: (value: string) => void;
}

const InlineEditableInput: React.FC<InlineEditableInputProps> = ({
  label,
  value,
  isLink = false,
  onChange,
  ...hookProps
}) => {
  const { inEditMode, editableRef, handleEnterEditMode, handleAccept, handleReset } = useInlineEditing(hookProps);

  return (
    <div className="columns is-2 is-vcentered mb-0">
      <div className="column is-4">
        <p className="has-text-weight-bold">
          {(!isLink || (isLink && !value)) && label}
          {isLink && value && (
            <a className="is-flex is-align-items-center" href={value} target="_blank" rel="noopener noreferrer">
              <span>{label}</span>
              <span className="icon">
                <i className="fas fa-arrow-up-right-from-square"></i>
              </span>
            </a>
          )}
        </p>
      </div>
      <div className="column is-8">
        {!inEditMode && (
          <React.Fragment>
            {/* {value && isLink && <a href={value} target="_blank" rel="noopener noreferrer" className="button is-small is-text">
                                <span className="icon">
                                    <i className="fas fa-arrow-up-right-from-square"></i>
                                </span>
                            </a>} */}
            <div className="input-display-mode-container" onClick={handleEnterEditMode}>
              {!value && <em className="has-text-warning">N/A</em>}
              {value && <p>{value}</p>}
            </div>
          </React.Fragment>
        )}
        {inEditMode && (
          <div
            className="input-edit-mode-container"
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAccept();
              if (e.key === 'Escape') handleReset();
            }}
          >
            <input
              ref={editableRef as React.RefObject<HTMLInputElement>}
              className="input is-small"
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
            <div className="edit-mode-buttons-container buttons has-addons">
              <button className="button is-small" onClick={handleAccept}>
                <span className="icon">
                  <i className="fa-solid fa-check"></i>
                </span>
              </button>
              <button className="button is-small" onClick={handleReset}>
                <span className="icon">
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InlineEditableInput;
