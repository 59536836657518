import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading';
import EditableTextArea from '../../components/job-applications/inline-edit/EditableTextArea';
import PotentialTag from '../../components/job-applications/PotentialTag';
import StateTag from '../../components/job-applications/StateTag';
import NotesList from '../../components/notes/NotesList';
import { JobApplicationPotential, JobApplicationState } from '../../models/Enums';
import { JobApplication } from '../../models/JobApplication';
import { deleteJobApplication, editJobApplication, getJobApplication } from '../../services/DatabaseService';
import InlineEditableInput from '../../components/job-applications/inline-edit/InlineEditableInput';
import InlineEditableSelect from '../../components/job-applications/inline-edit/InlineEditableSelect';
import InlineEditableDate from '../../components/job-applications/inline-edit/InlineEditableDate';

const JobApplicationDetails: React.FC = () => {
  const { application_id } = useParams();
  const [jobApplication, setJobApplication] = useState<JobApplication | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [jobTitle, setJobTitle] = useState<string>('');
  const [jobUrl, setJobUrl] = useState<string>('');
  const [state, setState] = useState<JobApplicationState>(JobApplicationState.Considering);
  const [potential, setPotential] = useState<JobApplicationPotential>(JobApplicationPotential.Low);
  const [appliedAt, setAppliedAt] = useState<Date | null>(null);
  const [cv, setCv] = useState<string>('');
  const [origin, setOrigin] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyPublicUrl, setCompanyPublicUrl] = useState<string>('');
  const [companyLinkedInUrl, setCompanyLinkedInUrl] = useState<string>('');
  const [compensationAsked, setCompensationAsked] = useState<string>('');
  const [compensationOffered, setCompensationOffered] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (application_id) {
          setIsLoading(true);
          const data = await getJobApplication(application_id);
          setJobApplication(data);

          setJobTitle(data?.job_title ? data?.job_title : '');
          setJobUrl(data?.job_url ? data?.job_url : '');
          setOrigin(data?.origin ? data?.origin : '');
          setState(data?.state ? data?.state : JobApplicationState.Considering);
          setPotential(data?.potential ? data?.potential : JobApplicationPotential.Low);
          setAppliedAt(data?.applied_at ? data?.applied_at : null);
          setCv(data?.cv ? data?.cv : '');
          setCompanyName(data?.company_name ? data?.company_name : '');
          setCompanyPublicUrl(data?.company_public_url ? data?.company_public_url : '');
          setCompanyLinkedInUrl(data?.company_linkedin_url ? data?.company_linkedin_url : '');
          setCompensationAsked(data?.compensation_asked ? data?.compensation_asked : '');
          setCompensationOffered(data?.compensation_offered ? data?.compensation_offered : '');
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [application_id]);

  if (isLoading) {
    return <Loading />;
  }

  if (!jobApplication) {
    return <div className="notification is-danger">Job Application not found!</div>;
  }

  const { job_description, cover_letter, notes, created_at, updated_at } = jobApplication;

  const handleDelete = async () => {
    setIsLoading(true);
    if (window.confirm('Are you sure you want to delete this job application?')) {
      try {
        if (jobApplication?.id) {
          await deleteJobApplication(jobApplication.id);
          navigate('/'); // Navigate to the home or list page after deletion
        }
      } catch (error) {
        console.error('Error deleting job application:', error);
        alert('Failed to delete job application.');
      }
    }
    setIsLoading(false);
  };

  //
  // Reset other fields when one field is being edited
  // This is to prevent multiple fields from being edited at the same time
  //

  // array to keep track of callbacks to reset other fields within their respective components
  var resetCallbacks: Array<() => void> = [];

  // function to reset other fields by iterating through the array of callbacks
  const resetOtherFields = () => {
    resetCallbacks.forEach((callback) => callback());
  };

  // function to register a callback to reset other fields.
  const registerForForcedReset = (callback: () => void) => {
    resetCallbacks.push(callback);
  };

  const createdAtTag = formatDistanceToNow(new Date(created_at), { addSuffix: true });
  const updatedAtTag = formatDistanceToNow(new Date(updated_at), { addSuffix: true });
  const showUpdatedAtTag = createdAtTag !== updatedAtTag;

  return (
    <React.Fragment>
      <Helmet>
        <title>Job Application Details - Jobsy</title>
      </Helmet>

      {/* Title (job name) */}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="is-flex is-flex-direction-column">
                <h1 className="title is-3">{jobTitle ? jobTitle : 'Unspecified job title'}</h1>
                <p className="subtitle is-6">{companyName}</p>
              </div>
            </div>
            <div className="column is-1 has-text-right">
              <button className="button is-danger is-small" onClick={handleDelete}>
                Delete
              </button>
            </div>
          </div>
          <hr />
        </div>

        <div className="container">
          <div className="columns is-5">
            <div className="column is-3 is-size-7">
              {/* Editable Fields */}
              <div className='container mb-6'>
                <h3 className="title is-5">Job</h3>
                <InlineEditableInput
                  label='Role'
                  onChange={(value) => setJobTitle(value)}
                  value={jobTitle}
                  acceptHandler={() => {
                    jobApplication.job_title = jobTitle;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setJobTitle(jobApplication.job_title || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />


                <InlineEditableInput
                  label='URL'
                  onChange={(value) => setJobUrl(value)}
                  value={jobUrl}
                  isLink={true}
                  acceptHandler={() => {
                    jobApplication.job_url = jobUrl;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setJobUrl(jobApplication.job_url || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableInput
                  label='Origin'
                  onChange={(value) => setOrigin(value)}
                  value={origin}
                  acceptHandler={() => {
                    jobApplication.origin = origin;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setOrigin(jobApplication.origin || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableSelect
                  label='State'
                  onChange={(value) => setState(value as JobApplicationState)}
                  value={state}
                  options={Object.values(JobApplicationState).map((item) => ({ value: item, label: item }))}
                  displayModeElement={<StateTag state={state} />}
                  acceptHandler={() => {
                    jobApplication.state = state;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setState(jobApplication.state || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableDate
                  label='Applied'
                  onChange={(value) => setAppliedAt(value)}
                  value={appliedAt}
                  acceptHandler={() => {
                    jobApplication.applied_at = appliedAt;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setAppliedAt(jobApplication.applied_at || null);
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableInput
                  label='CV'
                  onChange={(value) => setCv(value)}
                  value={cv}
                  acceptHandler={() => {
                    jobApplication.cv = cv;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCv(jobApplication.cv || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableSelect
                  label='Potential'
                  onChange={(value) => setPotential(value as JobApplicationPotential)}
                  value={potential}
                  options={Object.values(JobApplicationPotential).map((item) => ({ value: item, label: item }))}
                  displayModeElement={<PotentialTag potential={potential} />}
                  acceptHandler={() => {
                    jobApplication.potential = potential;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setPotential(jobApplication.potential || JobApplicationPotential.Low);
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />
              </div>
              <div className='container mb-6'>
                <h3 className="title is-5">Company</h3>
                <InlineEditableInput
                  label='Name'
                  onChange={(value) => setCompanyName(value)}
                  value={companyName}
                  acceptHandler={() => {
                    jobApplication.company_name = companyName;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCompanyName(jobApplication.company_name || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableInput
                  label='Website'
                  onChange={(value) => setCompanyPublicUrl(value)}
                  value={companyPublicUrl}
                  isLink={true}
                  acceptHandler={() => {
                    jobApplication.company_public_url = companyPublicUrl;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCompanyPublicUrl(jobApplication.company_public_url || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableInput
                  label='LinkedIn'
                  onChange={(value) => setCompanyLinkedInUrl(value)}
                  value={companyLinkedInUrl}
                  isLink={true}
                  acceptHandler={() => {
                    jobApplication.company_linkedin_url = companyLinkedInUrl;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCompanyLinkedInUrl(jobApplication.company_linkedin_url || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />
              </div>
              <div className='container mb-6'>
                <h3 className="title is-5">Compensation</h3>
                <InlineEditableInput
                  label='Offered'
                  onChange={(value) => setCompensationOffered(value)}
                  value={compensationOffered}
                  acceptHandler={() => {
                    jobApplication.compensation_offered = compensationOffered;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCompensationOffered(jobApplication.compensation_offered || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />

                <InlineEditableInput
                  label='Asked'
                  onChange={(value) => setCompensationAsked(value)}
                  value={compensationAsked}
                  acceptHandler={() => {
                    jobApplication.compensation_asked = compensationAsked;
                    editJobApplication(jobApplication);
                  }}
                  resetHandler={() => {
                    setCompensationAsked(jobApplication.compensation_asked || '');
                  }}
                  beginEditHandler={resetOtherFields}
                  registerForForcedReset={registerForForcedReset} />
              </div>

              <div className='container mb-6'>
                <h3 className="title is-5">Misc</h3>
                <div className="columns is-2 is-vcentered">
                  <div className="column is-4">
                    <p className="has-text-weight-bold">Created</p>
                  </div>
                  <div className="column is-8">
                    <p className="is-size-7" title={created_at?.toLocaleDateString()}>
                      <span>{createdAtTag}</span>
                    </p>
                  </div>
                </div>

                {showUpdatedAtTag &&
                  <div className="columns is-2 is-vcentered">
                    <div className="column is-4">
                      <p className="has-text-weight-bold">Updated</p>
                    </div>
                    <div className="column is-8">
                      <p className="is-size-7" title={updated_at?.toLocaleDateString()}>
                        <span>{updatedAtTag}</span>
                      </p>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className='column-divider'></div>

            <div className="column">
              <div className="container mb-6">
                <h4 className="title is-5">Job Description</h4>
                <EditableTextArea
                  content={job_description}
                  acceptHandler={(newValue?: string) => {
                    jobApplication.job_description = newValue;
                    editJobApplication(jobApplication);
                  }}
                ></EditableTextArea>
              </div>

              <div className="container mb-6">
                <h4 className="title is-5">Cover Letter</h4>
                <EditableTextArea
                  content={cover_letter}
                  acceptHandler={(newValue?: string) => {
                    jobApplication.cover_letter = newValue;
                    editJobApplication(jobApplication);
                  }}
                ></EditableTextArea>
              </div>

              <div className="container mb-6">
                <h4 className="title is-5">Notes</h4>
                <NotesList jobApplicationId={jobApplication.id!} notes={notes} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
export default JobApplicationDetails;
