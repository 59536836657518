import { formatDistanceToNow } from 'date-fns/formatDistanceToNow';
import React from 'react';
import { Note } from '../../models/Note';
import './NoteDisplay.scss';
import NoteForm from './NoteForm';

interface NoteDisplayProps {
  note: Note;
  jobApplicationId: string;
  onEdit?: (note: Note) => void;
  onEditDone?: () => void;
  isEditMode: boolean;
}

const NoteDisplay: React.FC<NoteDisplayProps> = ({ note, jobApplicationId, onEdit, onEditDone, isEditMode }) => {
  const createdAtTag = formatDistanceToNow(new Date(note.created_at), { addSuffix: true }).replace('about ', '');
  const updatedAtTag = formatDistanceToNow(new Date(note.updated_at), { addSuffix: true }).replace('about ', '');
  const showUpdatedAtTag = createdAtTag !== updatedAtTag;

  return (
    <article className="message is-small is-dark">
      <div className="message-body">
        {isEditMode && (
          <NoteForm jobApplicationId={jobApplicationId} noteToEdit={note} onEdit={onEditDone} onCancel={onEditDone} />
        )}
        {!isEditMode && (
          <React.Fragment>
            <div className="corner-top-right has-text-weight-light">
              <div className="level is-mobile">
                {!showUpdatedAtTag && <div>Created {createdAtTag}</div>}
                {showUpdatedAtTag && <div>Updated {updatedAtTag}</div>}
                {onEdit && (
                  <button className="button is-small is-text" aria-label="delete" onClick={() => onEdit(note)}>
                    <span className="icon">
                      <i className="fas fa-pencil"></i>
                    </span>
                  </button>
                )}
              </div>
            </div>
            <div className="note-contents" dangerouslySetInnerHTML={{ __html: note.content }}></div>
            <div className="corner-bottom-right has-text-weight-light">{note.type}</div>
          </React.Fragment>
        )}
      </div>
    </article>
  );
};

export default NoteDisplay;
