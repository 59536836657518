import React, { useEffect, useRef, useState } from 'react';
import './ShowMoreSection.scss';

interface ShowMoreSectionProps {
  children: React.ReactNode;
  maxHeight?: number;
}

function ShowMoreSection({ children, maxHeight = 100 }: ShowMoreSectionProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
  
    useEffect(() => {
      if (contentRef.current) {
        setIsOverflowing(contentRef.current.scrollHeight > maxHeight);
      }
    }, [children, maxHeight]);
  
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };
  
    return (
      <div className="show-more-container">
        <div
          ref={contentRef}
          className={`show-more-content content mb-1 ${isExpanded ? 'expanded' : 'collapsed'} ${isOverflowing ? 'overflowing' : ''}`}
          style={{
            maxHeight: isExpanded ? 'none' : `${maxHeight}px`,
            overflow: 'hidden',
          }}
        >
          {children}
        </div>
        {isOverflowing && (
          <button className="show-more-button" onClick={toggleExpand}>
            {isExpanded ? 'Show Less' : 'Show More'}
          </button>
        )}
      </div>
    );
  };

export default ShowMoreSection;
