import React from 'react';
import { useInlineEditing, UseInlineEditingProps } from './useInlineEditing';
import "./InlineEditableDate.scss";
import { formatDistanceToNow } from 'date-fns';
import DatePicker from 'react-datepicker';

interface InlineEditableDateProps extends UseInlineEditingProps {
    label: string;
    value: Date | null;
    isLink?: boolean;
    onChange: (value: Date | null) => void;
}

const InlineEditableDate: React.FC<InlineEditableDateProps> = ({
    label,
    value,
    isLink = false,
    onChange,
    ...hookProps
}) => {
    const {
        inEditMode,
        handleEnterEditMode,
        handleAccept,
        handleReset,
    } = useInlineEditing(hookProps);

    const distanceToNowTag = value ? formatDistanceToNow(value, { addSuffix: true }) : '';


    return (
        <div className="columns is-2 is-vcentered mb-0">
            <div className="column is-4">
                <p className="has-text-weight-bold">{label}</p>
            </div>
            <div className="column is-8">
                {!inEditMode && (
                    <div className="date-display-mode-container" onClick={handleEnterEditMode}>
                        {!value && <em className="has-text-warning">N/A</em>}
                        <span className="is-size-7">{distanceToNowTag}</span>
                    </div>
                )}
                {inEditMode && (
                    <div
                        className="date-edit-mode-container"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') handleAccept();
                            if (e.key === 'Escape') handleReset();
                        }}
                    >
                        <DatePicker
                            autoFocus={true}
                            selected={value}
                            onChange={(newValue) => onChange(newValue)}
                            className="input is-small is-fullwidth"
                            popperPlacement="bottom-end"
                        />
                        <div className="edit-mode-buttons-container buttons has-addons">
                            <button className="button is-small" onClick={handleAccept}>
                                <span className="icon">
                                    <i className="fa-solid fa-check"></i>
                                </span>
                            </button>
                            <button className="button is-small" onClick={handleReset}>
                                <span className="icon">
                                    <i className="fa-solid fa-xmark"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InlineEditableDate;
