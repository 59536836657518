import { useState, useRef, useEffect, useCallback } from 'react';

export interface UseInlineEditingProps {
  readOnly?: boolean;
  acceptHandler?: () => void;
  resetHandler?: () => void;
  beginEditHandler?: () => void;
  registerForForcedReset?: (callback: () => void) => void;
}

export const useInlineEditing = ({
  readOnly,
  acceptHandler,
  resetHandler,
  beginEditHandler,
  registerForForcedReset,
}: UseInlineEditingProps) => {
  const [inEditMode, setInEditMode] = useState(false);
  const editableRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (inEditMode && editableRef.current) {
      editableRef.current.focus();
    }
  }, [inEditMode]);

  const handleEnterEditMode = useCallback(() => {
    if (readOnly) return;
    beginEditHandler?.();
    setInEditMode(true);
  }, [readOnly, beginEditHandler]);

  const handleAccept = useCallback(() => {
    acceptHandler?.();
    setInEditMode(false);
  }, [acceptHandler]);

  const handleReset = useCallback(() => {
    resetHandler?.();
    setInEditMode(false);
  }, [resetHandler]);

  registerForForcedReset?.(() => {
    handleReset();
  });

  return {
    inEditMode,
    editableRef,
    handleEnterEditMode,
    handleAccept,
    handleReset,
  };
};
