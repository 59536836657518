import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import ShowMoreSection from '../../common/ShowMoreSection';

interface EditableTextAreaProps {
  content?: string;
  acceptHandler?: (newValue?: string) => void;
}

const EditableTextArea: React.FC<EditableTextAreaProps> = ({ content, acceptHandler }) => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const originalContent = content;
  const [editedContent, setEditedContent] = useState<string>(content || '');

  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (inEditMode && quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.focus();
    }
  }, [inEditMode]);

  const resetHandler = () => {
    setEditedContent(originalContent || '');
  };

  return (
    <React.Fragment>
      {!inEditMode && (
        <ShowMoreSection maxHeight={150}>
          <button
            className="button is-small"
            style={{ float: 'right' }}
            onClick={() => {
              setInEditMode(true);
            }}
          >
            <span className="icon">
              <i className="fa-solid fa-pencil"></i>
            </span>
            <span>Edit</span>
          </button>
          {editedContent ? (
            <div className="content" dangerouslySetInnerHTML={{ __html: editedContent }} />
          ) : (
            <em className="has-text-warning">No content</em>
          )}
        </ShowMoreSection>
      )}

      {inEditMode && (
        <React.Fragment>
          <div className="field">
            <div className="control">
              <ReactQuill ref={quillRef} theme="snow" value={editedContent} onChange={setEditedContent} />
            </div>
          </div>

          <div className="buttons">
            <button
              className="button is-small"
              onClick={() => {
                if (acceptHandler) {
                  setInEditMode(false);
                  if (quillRef.current) {
                    var trimmedContent = quillRef.current.getEditor().getText().trim();
                    if (trimmedContent && trimmedContent.length > 0) {
                      acceptHandler(editedContent);
                    } else {
                      acceptHandler('');
                    }
                  }
                }
              }}
            >
              <span className="icon">
                <i className="fa-solid fa-check"></i>
              </span>
              <span>Save</span>
            </button>
            <button
              className="button is-small"
              onClick={() => {
                setInEditMode(false);
                if (resetHandler) {
                  resetHandler();
                }
              }}
            >
              <span className="icon">
                <i className="fa-solid fa-xmark"></i>
              </span>
              <span>Cancel</span>
            </button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditableTextArea;
